import { Label, TextArea } from '../shared/styled/FormInput';
import { SelectWrapper } from '../advancedSearch/results/styled';
import Select from '../shared/Select';
import React, { useState } from 'react';
import { Option } from '../advancedSearch/selectors';
import { Question } from '../api/types';
import Tooltip from '../shared/Tooltip';
import moment, { Moment } from 'moment';
import Markdown from 'react-markdown';
import { HintStatus } from '../shared/FormInput';
import Input from '../shared/FormInput';

interface Props {
    question: Question,
    handleSelect: (answer: string, date: Moment|null) => void,
}

const QuestionEntry: React.FC<Props> = ({ question, handleSelect }) => {
    const [answer, setAnswer] = useState<Option | null>(null);

    const handleChange = (answer: string|string[]) => {
        if (Array.isArray(answer)) {
            answer = answer.join(', ');
        }
      handleSelect(answer, moment());
        setAnswer({
            label: answer,
            value: answer
        });
    };

    let inputType = question.inputType;
    if (!inputType) {
        const allowedAnswers = question.allowedAnswers || [];
        if (allowedAnswers.length > 0) {
            inputType = 'select';
        } else {
            inputType = 'text';
        }
    }

    return (
        <>
            <Tooltip
                id={question.id + '-tooltip'}
                overrides={{ place: 'bottom' }}
                trigger={<Label style={{ textAlign: 'center'}}>{question.title}</Label>}
            >
                <p>
                    {question.text}
                </p>
            </Tooltip>
            <SelectWrapper>
                <p>
                    <Markdown>
                        {question.text}
                    </Markdown>
                </p>
                {inputType === 'select' && (<Select
                    clearable={false}
                    placeholder={"Select " + question.title}
                    handleChange={handleChange}
                    options={question.allowedAnswers.map(a => {
                        return {
                            label: a,
                            value: a
                        };
                    })}
                    styleOverrides={{ background: 'transparent' }}
                    value={answer}
                />)}
                {inputType === 'text' && (
                    <Input
                        placeholder={question.title}
                        onInputChange={(e) => handleChange(e)}
                        onValidityChange={() => { }}
                        value={answer ? answer.label : ''}
                        label={''}
                        validity={[true]}
                    />
                )}
                {inputType === 'textarea' && (
                    <TextArea
                        hintStatus={HintStatus.hidden}
                        dual={false}
                        placeholder={question.title}
                        onChange={(e) => handleChange(e.target.value)}
                        value={answer ? answer.label : ''}
                    />
                )}
            </SelectWrapper>
        </>
    );
}

export default QuestionEntry;
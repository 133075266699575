import React from 'react';
import * as Styled from '../home/styled/Home';
import Copyright from '../shared/Copyright';
import LoadingIndicator from '../shared/LoadingIndicator';
import useApiGet from '../api/useApiGet';
import {
    Answer,
    ApiType,
    Question,
    Survey,
    SurveySubmissionRequest,
    SurveySubmissionResponse
} from '../api/types';
import styled, { css } from 'styled-components';
import { baseFont, breakpoints, colors } from '../../theme';
import { RouteComponentProps } from 'react-router-dom';
import useApiGetOne from '../api/useApiGetOne';
import SurveyForm from './SurveyForm';
import useApiCreate from '../api/useApiCreate';
import { getSurveyDescription } from './open-survey-tools';
import Markdown from 'react-markdown'

const Heading = styled.h1`
    color: ${colors.darkGray};
    ${baseFont({ size: 16, weight: 900 })}
    text-align: center;
    width: 100%;
    margin: 30px 0 0px 0;
    text-transform: uppercase;
    line-height: initial;
`;

interface ListItemProps {
    disabled: boolean;
    selected: boolean;
}
export const SurveyListItem = styled.li<ListItemProps>`
    ${baseFont()}
    width: 100%;
    position: relative;
    text-align: left;
    display: flex;
    flex-direction: column;
    @media (min-width: ${breakpoints.mediumMin}) {
        flex-direction: row;
        > div {
            &:first-of-type {
                margin-right: 76px;
            }
        }
    }
    align-items: flex-start;
    justify-content: space-between;
    background: rgba(44, 44, 44, ${p => (p.disabled ? '0.02' : '0.05')});
    color: rgba(44, 44, 44, ${p => (p.disabled ? '0.4' : '1')});
    &:first-of-type {
        margin-top: 32px;
    }
    margin-bottom: 20px;
    cursor: ${p => (p.disabled ? 'not-allowed' : 'pointer')};
    padding: 24px;
    border-radius: 4px;
    transition: all 0.2s ease-in-out;
    ${p =>
    !p.disabled &&
    css`
            &:hover {
                background: ${colors.titleGray};
                color: ${colors.white};
            }
        `}
    ${p =>
    p.selected &&
    css`
            background: ${colors.titleGray};
            color: ${colors.white};
        `}
`;

export const SurveyListItemDescription = styled.p`
    margin: 12px 0;
    a {
        font-weight: 700;
        color: ${colors.goldenrod};
        text-decoration: none;
    }
`;

//id to props
interface Props extends RouteComponentProps<{ id: string }> {}

const OpenSurvey: React.FC<Props> = ({ match }) => {
    console.log('filling out', match.params.id);
    const { data: survey, loading: surveysLoading } = useApiGetOne<Survey>(match.params.id, ApiType.Survey);
    const { data: questions, loading: questionsLoading } = useApiGet<Question>({
        surveys: [match.params.id],
        enabled: true,
    }, ApiType.Question);

    const { data: submission, create: createSubmission, loading: submissionLoading, error: submissionError } = useApiCreate<SurveySubmissionRequest, SurveySubmissionResponse>(ApiType.SurveySubmission);

    const surveyDescription = survey ? getSurveyDescription(survey) : '';

    const submitForm = (
        name: string,
        email: string,
        code: string|null,
        isPublic: boolean,
        answers: Answer[]
    ) => {
        if (survey) {
            createSubmission({
                survey: survey.id,
                name,
                email: email !== '' ? email : null,
                code,
                public: isPublic,
                answers,
            });
            //scroll to top
            window.scrollTo(0, 0);
        }
    }

    console.log('survey', survey);
    console.log('questions', questions);
    console.log('submission', submission);
    console.log('submission error', submissionError);

    const loading = surveysLoading || questionsLoading;

    let submissionResult = null;

    if (submission) {
        submissionResult = (
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                backgroundColor: 'rgba(0, 255, 0, 0.2)',
                padding: '24px',
                borderRadius: '4px',
                marginTop: '20px',
            }}>
                <p>
                    Thank you for filling out the survey!
                    <br /><br />
                    Your response has been filed and we appreciate your contribution to our research. We may reach out for additional questions or to provide the results of the survey or other reflections on your submission.
                    <br /><br />
                    Please use the following code to reference your submission in the future:
                    <hr />
                    <code>
                        Response ID: {submission.responseSet}
                        <br />
                        Participant Code: {submission.respondentCode}
                    </code>
                    <hr />
                    If you have any questions, please contact us at <a href="mailto:team@elsewhere.to">team@elsewhere.to</a>
                </p>
            </div>
        );
    } else if (submissionError) {
        submissionResult = (
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                backgroundColor: 'rgba(255, 0, 0, 0.2)',
                padding: '15px',
                borderRadius: '4px',
                marginTop: '20px',
            }}>
                <p>
                    Something went wrong while submitting the survey! Please correct the error or refresh the page and try again.
                </p>
                <code>{submissionError.code} - {submissionError.message}</code>
            </div>
        );
    }

    return (
        <Styled.Wrapper>
            {loading && <LoadingIndicator />}
            {!loading && survey && questions && (
                <>
                    {submissionResult}
                    <Heading>{survey.name}</Heading>
                    <SurveyListItem
                        disabled={false}
                        selected={true}
                    >
                        <SurveyListItemDescription>
                            <Markdown>
                                {surveyDescription}
                            </Markdown>
                        </SurveyListItemDescription>
                    </SurveyListItem>
                    <SurveyForm survey={survey} questions={questions} onSubmit={submitForm} />
                    <Copyright />
                </>
            )}
        </Styled.Wrapper>
    );
};

export default OpenSurvey;

import React, { useState } from 'react';
import { Answer, Question, Survey } from '../api/types';
import SurveyQuestion from './SurveyQuestion';
import Button from '../shared/Button';
import { Input, Label } from '../shared/styled/FormInput';
import { HintStatus } from '../shared/FormInput';
import Tooltip from '../shared/Tooltip';
import SurveyUserAgreement from './SurveyUserAgreement';
import { SelectWrapper } from '../advancedSearch/results/styled';
import Select from '../shared/Select';
import { Option } from '../advancedSearch/selectors';
import { extractIdFromPath } from '../api/utils';
import styled from 'styled-components';
import { baseFont, colors } from '../../theme';

const Heading = styled.h1`
    color: ${colors.darkGray};
    ${baseFont({ size: 16, weight: 900 })}
    text-align: center;
    width: 100%;
    margin: 30px 0 0px 0;
    text-transform: uppercase;
    line-height: initial;
`;

//id to props
interface Props {
    survey: Survey,
    questions: Question[],
    onSubmit: (
        name: string,
        email: string,
        code: string | null,
        isPublic: boolean,
        answers: Answer[]
    ) => void
}

const SurveyForm: React.FC<Props> = ({ survey, questions, onSubmit }) => {
    const [answers, setAnswers] = useState<Answer[]>([]);
    const [participantName, setParticipantName] = useState<string>('');
    const [participantEmail, setParticipantEmail] = useState<string>('');
    const [participantCode, setParticipantCode] = useState<string>('');

    const privateAllowed = survey.userAgreementType !== 'public';

    const [participantPrivacy, setParticipantPrivacy] = useState<Option>({
        label: 'Public',
        value: 'Public'
    });
    const [userAgreement, setUserAgreement] = useState<boolean>(false);

    const canSubmit = userAgreement;

    const addAnswer = (answer: Answer) => {
        //find the answer by question
        const existingAnswer = answers.find(a => a.question === answer.question);
        if (existingAnswer) {
            //replace the answer
            const newAnswers = answers.map(a => a.question === answer.question ? answer : a);
            setAnswers(newAnswers);
        } else {
            //add the answer
            setAnswers([...answers, answer]);
        }
    }

    const submitSurvey = () => {
        onSubmit(
            participantName,
            participantEmail,
            participantCode.length > 0 ? participantCode : null,
            participantPrivacy.value === 'Public',
            answers
        );
    }

    //sort the questions by their IDs as they appear in the survey questions array (low to high)
    const sortedQuestions = questions.sort((a, b) => {
        const surveyQuestionIds = survey.questions.map(q => extractIdFromPath(q));
        return surveyQuestionIds.indexOf(a.id) - surveyQuestionIds.indexOf(b.id);
    });

    const normalQuestions = sortedQuestions.filter(q => q.type !== 'demographic');

    //extract those of type 'demographic'
    const demographicQuestions = sortedQuestions.filter(q => q.type === 'demographic');

    console.log('sorted questions', sortedQuestions);

    return (
        <div style={{minWidth: 400, width: '75%'}}>
            <Heading>Participant Information</Heading>
            <br />
            <div style={{
                marginBottom: 20
            }}>
                <Tooltip
                    id="name-tooltip"
                    overrides={{ place: 'bottom' }}
                    trigger={<Label style={{ textAlign: 'center'}}>Name</Label>}
                >
                    <p>
                        The name you provide is confidential and if provided will never be displayed.
                    </p>
                </Tooltip>
                <Input
                    hintStatus={HintStatus.hidden} dual={false}
                    value={participantName}
                    placeholder="Name (optional)"
                    onChange={e => setParticipantName(e.target.value)}
                />
            </div>
            <div style={{
                marginBottom: 20
            }}>
                <Tooltip
                    id="email-tooltip"
                    overrides={{ place: 'bottom' }}
                    trigger={<Label style={{ textAlign: 'center'}}>Email address</Label>}
                >
                    <p>
                        The email address will never be displayed. If provided, it will only be used to contact you about your submission or to provide requested feedback and results.
                    </p>
                </Tooltip>
                <Input
                    hintStatus={HintStatus.hidden} dual={false}
                    value={participantEmail}
                    placeholder="Email address (optional)"
                    onChange={e => setParticipantEmail(e.target.value)}
                />
            </div>
            <div style={{
                marginBottom: 20
            }}>
                <Tooltip
                    id="code-tooltip"
                    overrides={{ place: 'bottom' }}
                    trigger={<Label style={{ textAlign: 'center'}}>Participant Code</Label>}
                >
                    <p>
                        You can provide the participant code from previous submissions so that your responses can be linked together. This will allow you to more easily view all of your responses at a later date.<br /><br />
                        This is optional and will be automatically generated if this is your first submission.
                    </p>
                </Tooltip>
                <Input
                    hintStatus={HintStatus.hidden} dual={false}
                    value={participantCode}
                    placeholder="Participant code (optional)"
                    onChange={e => setParticipantCode(e.target.value)}
                />
            </div>
            <Heading>Demographic Details</Heading>
            {demographicQuestions.map((question) => (
                <SurveyQuestion
                    key={question.id}
                    question={question}
                    addAnswer={addAnswer} />
            ))}
            <Heading>{survey.name.replace(/\s*\(.+\)/, '')}</Heading>
            {normalQuestions.map((question) => (
                <SurveyQuestion
                    key={question.id}
                    question={question}
                    addAnswer={addAnswer} />
            ))}
            {false && (<div style={{
                marginBottom: 20
            }}>
                <Tooltip
                    id={'public-tooltip'}
                    overrides={{ place: 'bottom' }}
                    trigger={<Label style={{ textAlign: 'center'}}>Public/Private Submission</Label>}
                >
                    <p>
                        {privateAllowed ? (
                            <>
                                You may elect to make your responses public or private. While all responses are anonymized, if you choose to make your responses public, they may be displayed publicly on the site and used in other research in the SDDb.<br /><br />
                                Please read the User Agreement for further information.
                                </>
                        ) : (
                            <>
                               This survey requires that all responses be public. Public responses will be anonymized but may be displayed publicly on the site and used in other research in the SDDb.<br /><br />
                                Please read the User Agreement for further information.
                            </>
                        )}
                    </p>
                </Tooltip>
                <SelectWrapper>
                    <Select
                        clearable={false}
                        isDisabled={!privateAllowed}
                        placeholder={"Select privacy level"}
                        handleChange={(value) => setParticipantPrivacy({
                            label: value,
                            value: value
                        })}
                        options={[
                            {
                                label: 'Public',
                                value: 'Public'
                            },
                            {
                                label: 'Private',
                                value: 'Private'
                            }
                        ]}
                        styleOverrides={{ background: 'transparent' }}
                        value={participantPrivacy}
                    />
                </SelectWrapper>
            </div>)}
            <div style={{
                marginBottom: 20,
                textAlign: 'center'
            }}>
                <Tooltip
                    id="user-agreement-tooltip"
                    overrides={{ place: 'bottom' }}
                    trigger={<Label style={{ textAlign: 'center'}}>User Agreement</Label>}
                >
                    <p>
                        You must agree to the User Agreement before submitting your survey response.
                    </p>
                </Tooltip>
                <SurveyUserAgreement survey={survey} />
                <span>I have read and agree to the above User Agreement</span>
                <input
                    value={userAgreement ? 'true' : 'false'}
                    onChange={e => setUserAgreement(e.target.checked)}
                    type={"checkbox"}
                />
            </div>
            {canSubmit ? (
                <Button onClick={submitSurvey}>Submit</Button>
                ) : (
                <Button disabled={true} style={{
                    cursor: 'not-allowed',
                    backgroundColor: '#ccc',
                    color: '#000',
                    border: '1px solid #ccc'
                }}>Submit</Button>
            )}


        </div>
    );
};

export default SurveyForm;
